import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import passo1 from "../../images/servicos/svg/1.svg"
import passo2 from "../../images/servicos/svg/2.svg"
import semanas from "../../images/servicos/svg/2Semanas.svg"

const Info = ({ data }) => {
  
  return(
    <StyledInfo>
      <div className="passo">
        <div className={font.rEBI + " subtitle"}>{data.facetas.title}</div>
        <div className={font.rR + " txt"}>{data.facetas.text}</div>
        <br/><br/>

        <div className="questoes">
          <div className={font.rEBI + " subtitle"}>{data.facetas.facetaQuestao1.title}</div>
          <div className={font.rR + " txt"}>
            {data.facetas.facetaQuestao1.text.map((data, i)=>(
              <>
                <div className="instruc">
                  {i===2 && <img src={semanas} alt="2 Semanas" className="iconBig" />}
                </div>
                <div className="titleFlex">
                  {i===1 && <img src={passo1} alt="Passo 1" className="icon" />}
                  {i===3 && <img src={passo2} alt="Passo 2" className="icon" />}
                  {i!==2 && <span key={"facetaQuestao1" + i}>{data}<br/></span>}
                </div>
                {(i===0 || i===2)  && <><br/></>}
              </>
            ))}
          </div>
        </div>
        <br/><br/>

        <div className="questoes">
          <div className={font.rEBI + " subtitle"}>{data.facetas.facetaQuestao2.title}</div>
          <div className={font.rR + " txt"}>
            {data.facetas.facetaQuestao2.text.map((data, i)=>(
              <span key={"facetaQuestao2" + i}>{data}<br/></span>
            ))}
          </div>
        </div>
        <br/><br/>

        <div className="questoes">
          <div className={font.rEBI + " subtitle"}>{data.facetas.facetaQuestao3.title}</div>
          <div className={font.rR + " txt"}>
            {data.facetas.facetaQuestao3.text.map((data, i)=>(
              <span key={"facetaQuestao3" + i}>{data}<br/></span>
            ))}
          </div>
        </div>
      </div>

      <div className="passo">
        <div className={font.rEBI + " subtitle"}>{data.branqueamento.title}</div>
        <div className={font.rR + " txt"}>{data.branqueamento.text}</div>
        <br/><br/>

        <div className="questoes">
          <div className={font.rEBI + " subtitle"}>{data.branqueamento.branqQuestao1.title}</div>
          <div className={font.rR + " txt"}>
            {data.branqueamento.branqQuestao1.text.map((data, i)=>(
              <span key={"branqQuestao1" + i}>{data}<br/></span>
            ))}
          </div>
        </div>
        <br/><br/>

        <div className="questoes">
          <div className={font.rEBI + " subtitle"}>{data.branqueamento.branqQuestao2.title}</div>
          <div className={font.rR + " txt"}>
            {data.branqueamento.branqQuestao2.text.map((data, i)=>(
              <span key={"branqQuestao2" + i}>{data}<br/></span>
            ))}
          </div>
        </div>
        <br/><br/>

        <div className="questoes">
          <div className={font.rEBI + " subtitle"}>{data.branqueamento.branqQuestao3.title}</div>
          <div className={font.rR + " txt"}>
            {data.branqueamento.branqQuestao3.text.map((data, i)=>(
              <span key={"branqQuestao3" + i}>{data}<br/></span>
            ))}
          </div>
        </div>
        <br/><br/>

        <div className="questoes">
          <div className={font.rEBI + " subtitle"}>{data.branqueamento.branqQuestao4.title}</div>
          <br/><br/>
          <div className={font.rR + " txt"}>
            {data.branqueamento.branqQuestao4.text.map((data, i)=>(
              <>
              <div className="titleFlex">
                {i===0 && <img src={passo1} alt="Passo 1" className="icon" />}
                {i===1 && <img src={passo2} alt="Passo 2" className="icon" />}
                <span key={"branqQuestao4" + i}>{data}<br/></span>
              </div>
              {i===0  && <><br/></>}
            </>
            ))}
          </div>
        </div>
      </div>
    </StyledInfo>
  )
}

export default Info

const StyledInfo = styled.div`
  position: relative;
  margin: 5vw auto calc(100px + 100 * (100vw - 320px) / 1600);
  width: calc(280px + 800 * (100vw - 320px) / 1600);
  @media only screen and (min-width: 1920px){
    width: 1080px;
    margin: 5vw auto 200px;
  }
  .title{
    font-size: calc(16px + 22 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px){
      font-size: 38px;
    }
    letter-spacing: 0.1em;
    color: #231f20;
    text-transform: uppercase;
    margin-bottom: 2em;
  }
  .subtitle{
    font-size: calc(14px + 10 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px){
      font-size: 24px;
    }
    letter-spacing: 0.1em;
    line-height: 1.6em;
    color: #231f20;
  }
  .txt{
    font-size: calc(12px + 12 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px){
      font-size: 22px;
    }
    letter-spacing: 0.1em;
    line-height: 1.6em;
    color: #808080;
  }
  .passo{
    margin: calc(30px + 20 * (100vw - 320px) / 1600) 0;
    @media only screen and (min-width: 1920px){
      margin: 50px 0;
    }
  }
  .titleFlex{
    display: flex;
    align-items: center;
  }
  .icon{
    width: calc(66px + 34 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px){
      width: 100px;
    }
  }
  .instruc{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .iconBig{
    width: 300px;
    margin-top: 20px;
    max-width: 40vw;
  }
  .marginBot{
    margin-bottom: 25px;
  }
  .marginRight{
    margin-right: 15px;
  }
`